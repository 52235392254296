/* TODO: Column visibility tooltip position */
/* TODO: Shift this to datagrid css file */
/* div[role='tooltip'] {
  top: -60px !important;
  transform: translate3d(1030px, 229px, 0px) !important;
} */

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
